import { RouteDefinition } from "../types/RouteDefinition"
import { match, matchPath } from "react-router-dom"

export const matchRoute = <TContext>(
  path: string,
  routes: RouteDefinition<TContext>[]
): { route?: RouteDefinition<TContext>; match?: match } => {
  for (const route of routes) {
    const match = matchPath(path, {
      path: route.path,
      exact: route.exact,
    })

    if (match) {
      return { route, match }
    }
  }

  return {}
}
