import { ComponentType } from "react"

export type ComponentWithPreloader = {
  component: ComponentType
  preloader?: () => any
}

export const loadComponentFromModule = async (load: () => Promise<any>): Promise<ComponentWithPreloader> => {
  // artificially delay transitions to prevent "too fast" navigation
  // and flickering, also useful for debugging
  const minimalTransitionDuration = 200

  const loadModule = async () => {
    try {
      return await load()
    } catch (err) {
      console.error(err)
      throw new Error("Could not load module")
    }
  }

  const loadTransition = async () => {
    if (minimalTransitionDuration > 0) {
      return new Promise((resolve) => {
        setTimeout(resolve, minimalTransitionDuration)
      })
    }
  }

  const [module] = await Promise.all([loadModule(), loadTransition()])
  const specialModuleKeys = ["preloader"]
  const moduleKeys = Object.keys(module).filter((key) => !specialModuleKeys.includes(key))

  if (moduleKeys.length === 0) {
    throw new Error("Trying to import a module that does not export anything")
  }

  if (moduleKeys.length > 1) {
    throw new Error(`A lazy module must have exactly one export, found multiple: ${moduleKeys.join(",")}`)
  }

  const firstExport = moduleKeys.pop()!
  const component = module[firstExport]
  const preloader = module["preloader"]

  if (typeof component !== "function") {
    throw new Error(`Export "${firstExport}" from loaded module is not a function`)
  }

  return {
    component,
    preloader,
  }
}
