import { CreateValueSetter } from "./types"

export const createValueSetter: CreateValueSetter = (value) => {
  return (newValue) => {
    if (newValue instanceof Function) {
      value.set(newValue(value.get()))
    } else {
      value.set(newValue)
    }
  }
}
