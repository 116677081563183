import { GenericLogger } from "./GenericLogger"

export class Logger implements GenericLogger {
  prefix: string
  enabled: boolean

  constructor(prefix: string, enabled = true) {
    this.prefix = `[${prefix}]:`
    this.enabled = enabled
  }

  log(...args: any[]) {
    this.enabled && console.log(this.prefix, ...args)
    return this
  }

  error(...args: any[]) {
    this.enabled && console.error(this.prefix, ...args)
    return this
  }

  info(...args: any[]) {
    this.enabled && console.info(this.prefix, ...args)
    return this
  }

  warn(...args: any[]) {
    this.enabled && console.warn(this.prefix, ...args)
    return this
  }

  debug(...args: any[]) {
    this.enabled && console.debug(this.prefix, ...args)
    return this
  }

  group(...args: any[]) {
    this.enabled && console.group(this.prefix, ...args)
    return this
  }

  groupEnd() {
    this.enabled && console.groupEnd()
    return this
  }
}
